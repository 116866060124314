import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../base/request';
import { defaultSetting } from './default';
export const useOngoingWithdraw = (address, maxCount, pageId) => {
    let count = !maxCount ? 100 : maxCount;
    return useQuery(['ongoingWithdrawTx', address, pageId], async () => {
        if (!address)
            return null;
        return (await apiClient.get(`tx/getOngoingWithdrawByAddress?address=${address}&maxCount=0x${count?.toString(16)}${pageId ? `&lastID=${pageId}` : ''}`)).data.data;
    }, {
        ...defaultSetting,
        enabled: !!address,
        staleTime: 1000 * 5,
        cacheTime: 1000 * 5,
        keepPreviousData: true,
    });
};
