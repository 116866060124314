import { Theme } from '@node-real/uikit';

import { colors } from './colors';

export const theme: Theme = {
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
    storageKey: 'combo-color-mode-bridge',
  },

  ...colors,
  breakpoints: {
    md: '768px',
    lg: '1281px',
  },

  styles: {
    global: {
      body: {
        bg: 'bg.middle',
        color: 'readable.normal',
        lineHeight: 'normal',
        WebkitTapHighlightColor: 'transparent',
        fontFamily: 'Space Grotesk',
      },
      '[data-theme="light"]': {
        '.cw-navbar .ui-close-button': {
          color: 'readable.disabled',
        },
        '.ui-modal-content': {
          background: '#fff',
          '.ui-modal-header': {
            color: 'bg.card',
          },
          '.ui-modal-footer': {
            '.cw-download-link': {
              color: 'bg.card',
              '.ui-button': {
                color: 'bg.card',
              },
            },
          },
        },
        '.ui-modal-body': {
          '.cw-description': {
            color: 'readable.pageButton',
          },
        },
        '.ui-collapse': {
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#333',
            outline: '1px solid #333',
            borderRadius: '8px',
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '8px',
          },
        },
      },
    },
  },
};
