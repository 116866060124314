import { Flex } from '@node-real/uikit';
import styled from '@emotion/styled';
import { SocialLinks } from '@op-bridge/bridge-core';

import { reportEvent, GA_MAP } from '../../utils/ga';
const Footer = () => {
  return (
    <StyledFooter>
      <SocialLinks
        discordLink="https://discord.com/invite/bnbchain"
        telegramLink="https://t.me/bnbchain"
        youtubeLink="https://www.youtube.com/channel/UCG9fZu6D4I83DStktBV0Ryw"
        githubLink="https://github.com/bnb-chain/opbnb"
        twitterLink="https://twitter.com/BNBChain"
        onDiscordClick={() => {
          reportEvent({
            name: GA_MAP.footerDiscord,
            data: { name: 'Footer Discord Link Click' },
          });
        }}
        onGithubClick={() => {
          reportEvent({
            name: GA_MAP.footerGithub,
            data: { name: 'Footer GitHub Link Click' },
          });
        }}
        onTelegramClick={() => {
          reportEvent({
            name: GA_MAP.footerTelegram,
            data: { name: 'Footer Telegram Link Click' },
          });
        }}
        onTwitterClick={() => {
          reportEvent({
            name: GA_MAP.footerTwitter,
            data: { name: 'Footer Twitter Link Click' },
          });
        }}
        onYoutubeClick={() => {
          reportEvent({
            name: GA_MAP.footerYoutube,
            data: { name: 'Footer YouTube Link Click' },
          });
        }}
      />
      <FlexContainer justifyContent={'center'} marginTop={3}>
        <CopyRight>
          © {new Date().getFullYear()}{' '}
          <Link href="https://www.bnbchain.org/" target="_blank" rel="noreferrer">
            BNB Chain
          </Link>
          . All rights reserved.
        </CopyRight>
      </FlexContainer>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  width: 100%;
`;

const CopyRight = styled.div`
  padding: 13px;
  text-align: center;
  color: ${(props: any) => props.theme.colors.readable?.secondary};
`;

const FlexContainer = styled(Flex)`
  background-color: ${(props: any) => props.theme.colors.bg?.middle};
`;

const Link = styled.a`
  text-decoration: underline;
  &:hover {
    color: ${(props: any) => props.theme.colors.scene.primary.normal};
  }
`;
