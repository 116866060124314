import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Modal, } from '@node-real/uikit';
import { useCallback, useEffect } from 'react';
import { ConnectorNotFoundError } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { useRouter } from 'next/router';
import { WalletItem } from './WalletItem';
import { MetamaskIcon } from './MetamaskIcon';
import { TrustWalletIcon } from './TrustWalletIcon';
import { useWallet, useWalletModal } from './hooks';
export function WalletConnectModal(props) {
    const { isOpen, onClose, l1ChainId, l2ChainId, onWalletItemClick } = props;
    const router = useRouter();
    const chainId = router && router?.asPath?.includes('withdraw') ? Number(l2ChainId) : Number(l1ChainId);
    const { handleModalClose } = useWalletModal();
    const onConnectError = useCallback((err, args) => {
        if (err instanceof ConnectorNotFoundError) {
            const { connector } = args;
            if (connector instanceof MetaMaskConnector) {
                window.open('https://metamask.io/download/', '_blank');
            }
            else if (connector instanceof InjectedConnector && connector.name === 'Trust Wallet') {
                window.open('https://trustwallet.com/browser-extension', '_blank');
            }
        }
    }, []);
    const onSuccess = useCallback(() => {
        handleModalClose();
    }, [handleModalClose]);
    const { isLoading: isWalletConnecting, connectors, connector, onChangeConnector, disconnect, } = useWallet({
        chainId: chainId,
        onSuccess,
        onConnectError,
    });
    const isLoading = isWalletConnecting;
    useEffect(() => {
        if (isOpen) {
            disconnect();
        }
    }, [disconnect, isOpen]);
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => {
            onClose();
        }, w: 484, background: 'readable.white', color: 'bg.card', children: [_jsx(ModalCloseButton, {}), _jsx(ModalHeader, { children: "Connect a Wallet" }), _jsx(ModalBody, { mt: 34, background: 'readable.white', children: connectors?.map((item, index) => {
                    const options = getOptionsByWalletName(item.name);
                    const isActive = isLoading && connector?.name === item.name;
                    return (_jsx(WalletItem, { icon: options?.icon, name: item.name, isActive: isActive, isDisabled: isLoading, onClick: () => {
                            onWalletItemClick();
                            onChangeConnector(item);
                        } }, index));
                }) }), _jsxs(ModalFooter, { mt: 40, fontSize: 14, fontWeight: 400, lineHeight: "17px", color: "readable.tertiary", gap: 4, gridGap: 4, children: ["Don't have a wallet?", _jsx(Link, { href: 'https://trustwallet.com/browser-extension', isExternal: true, color: "inherit", textDecoration: "underline", children: "Get one here!" })] })] }));
}
function getOptionsByWalletName(walletName) {
    switch (walletName) {
        case 'MetaMask':
            return {
                icon: _jsx(MetamaskIcon, { width: 52, height: 52 }),
            };
        case 'Trust Wallet':
            return {
                icon: _jsx(TrustWalletIcon, { width: 52, height: 52 }),
            };
        default:
            return null;
    }
}
