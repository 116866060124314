import React from 'react';
// import { getCookie } from 'cookies-next';

// check whether coolie exist
// const cookie = getCookie('term-condition');

export let initialState: any;
// if (cookie) {
initialState = {
  open: false,
};
// } else {
//   initialState = {
//     open: true,
//   };
// }

export interface ModalState {
  modalState: {
    open: boolean;
  };
  modalDispatch: React.Dispatch<any>;
}

export const ModalContext = React.createContext<ModalState>(null as any);
ModalContext.displayName = 'ModalContext';

export const ModalReducer = (initialState: any, action: any) => {
  switch (action.type) {
    case 'OPEN':
      return {
        open: true,
      };
    case 'CLOSE':
      return {
        open: false,
      };
    default:
      return initialState;
  }
};

export * from './Provider';
