import { FAQMobile } from '@op-bridge/bridge-core';

import { FAQContent } from './FAQContent';
import { reportEvent, GA_MAP } from '../../utils/ga';

export const StickyFAQMobile = () => {
  return (
    <FAQMobile
      faqTitle={'Frequently asked questions'}
      discordGA={() => {
        reportEvent({ name: GA_MAP.faqDiscord, data: { name: 'Click Discord Button' } });
      }}
      closeGA={() => {
        reportEvent({ name: GA_MAP.faqCloseButton, data: { name: 'Click Close Button' } });
      }}
      buttonGA={() => {
        reportEvent({ name: GA_MAP.faqButtonClick, data: { name: 'Click Help Button' } });
      }}
    >
      <FAQContent />
    </FAQMobile>
  );
};
