import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../base/request';
import { defaultSetting } from './default';
export const useWithdrawHistoryPageKey = (address, pageKey, pageSize = 500, enabled = false, p) => {
    const result = useQuery(['withdrawTxPageKey', address, pageKey, pageSize, p], async () => {
        return (await apiClient.get(`tx/getAssetTransferByAddress?address=${address}&pageSize=${pageSize}&pageKey=${pageKey}&type=withdraw`)).data.data;
    }, {
        ...defaultSetting,
        enabled: !!address && enabled,
        staleTime: 1000 * 5,
        cacheTime: 1000 * 5,
        refetchInterval: 1000 * 5,
        keepPreviousData: false,
    });
    if (enabled === true) {
        return result;
    }
    else {
        return { data: null, isFetching: false };
    }
};
