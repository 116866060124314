import React from 'react';
export let initialState;
initialState = {
    open: false,
};
export const WalletModalContext = React.createContext(null);
WalletModalContext.displayName = 'WalletModalContext';
export const WalletModalReducer = (initialState, action) => {
    switch (action.type) {
        case 'OPEN':
            return {
                open: true,
            };
        case 'CLOSE':
            return {
                open: false,
            };
        default:
            return initialState;
    }
};
export * from './Provider';
