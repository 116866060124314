import { rgba } from '@node-real/uikit';

export const light = {
  colors: {
    readable: {
      normal: '#FFFFFF',
      secondary: '#AEAFB0',
      disabled: '#535458',
      border: '#2F3034',
      white: '#FFFFFF',
      link: '#CC9D09',
      pageButton: '#8C8D8F',
      grey10: '#B9B9BB',
      top: {
        secondary: '#F7F7F7',
      },
    },

    bg: {
      bottom: '#8C8C8C',
      menu: { hover: '#F7F7F7' },
      middle: '#181A1E',
      card: '#1E2026',
      codebox: '#000000',
      table: '#1E2026',
      status: '#35363C',
      top: {
        normal: '#F5F5F5',
        active: '#E6E8EA',
      },
    },

    scene: {
      primary: {
        normal: '#F0B90B',
        active: '#F8D12F',
        opacity: rgba('#F8D12F', 0.1),
        semiOpacity: rgba('#F8D12F', 0.15),
        primaryOpacity: rgba('#F0B90B', 0.1),
      },

      success: {
        normal: '#29CA0E',
        active: '#30EE11',
        opacity: rgba('#30EE11', 0.1),
        progressBar: '#02C076',
      },

      danger: {
        normal: '#D9304E',
        active: '#B82942',
        opacity: rgba('#D9304E', 0.1),
        hover: rgba('#D9304E', 0.2),
      },

      warning: {
        normal: '#CC9D09',
        active: '#F0B90B',
        opacity: rgba('#F0B90B', 0.1),
        hover: rgba('#F0B90B', 0.2),
      },

      orange: {
        normal: '#F15903',
        active: '#F1943C',
        warning: '#CA690E',
        background: rgba('#F15903', 0.1),
      },

      blue: {
        normal: '#0270C0',
        active: '#308ACB',
        approve: { normal: '#1184EE', active: '#3C9AF1' },
      },
    },
  },

  shadows: {
    normal: '0px 4px 24px rgba(0, 0, 0, 0.04)',
  },
};
