import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Tooltip } from '@node-real/uikit';
import styled from '@emotion/styled';
import { Discord } from '../svgIcon/Discord';
import { Twitter } from '../svgIcon/Twitter';
import { Youtube } from '../svgIcon/Youtube';
import { Telegram } from '../svgIcon/Telegram';
import { Github } from '../svgIcon/Github';
import { mobileMedia } from '../../hooks';
export const SocialLinks = (props) => {
    const { twitterLink, discordLink, youtubeLink, telegramLink, githubLink, onTwitterClick, onTelegramClick, onDiscordClick, onGithubClick, onYoutubeClick, } = props;
    return (_jsxs(Flex, { gap: [0, 16, 16], flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: [36, 13, 13], children: [discordLink && (_jsx(StyledTooltip, { placement: "top", content: "Discord", children: _jsx(IconLink, { href: discordLink, target: "_blank", onClick: onDiscordClick, children: _jsx(Discord, { width: 24, height: 24 }) }) })), twitterLink && (_jsx(StyledTooltip, { placement: "top", content: "Twitter", children: _jsx(IconLink, { className: "not-first-icon", href: twitterLink, target: "_blank", onClick: onTwitterClick, children: _jsx(Twitter, { width: 16, height: 14 }) }) })), youtubeLink && (_jsx(StyledTooltip, { placement: "top", content: "YouTube", children: _jsx(IconLink, { className: "not-first-icon", href: youtubeLink, target: "_blank", onClick: onYoutubeClick, children: _jsx(Youtube, { width: 24, height: 24 }) }) })), telegramLink && (_jsx(StyledTooltip, { placement: "top", content: "Telegram", children: _jsx(IconLink, { className: "not-first-icon", href: telegramLink, target: "_blank", onClick: onTelegramClick, children: _jsx(Telegram, { width: 24, height: 24 }) }) })), githubLink && (_jsx(StyledTooltip, { placement: "top", content: "GitHub", children: _jsx(IconLink, { className: "not-first-icon", href: githubLink, target: "_blank", onClick: onGithubClick, children: _jsx(Github, { width: 24, height: 24 }) }) }))] }));
};
const IconLink = styled.a `
  color: ${(props) => props.theme.colors.readable?.pageButton};
  display: flex;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  &:hover {
    color: white;
  }
  ${mobileMedia} {
    &.not-first-icon {
      margin-left: 16px;
    }
  }
`;
const StyledTooltip = styled(Tooltip) `
  background: white;
  font-family: 'Space Grotesk';
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors?.bg.card};
`;
