import { FAQ } from '@op-bridge/bridge-core';

import { FAQContent } from './FAQContent';
import { GA_MAP, reportEvent } from '../../utils/ga';

export const StickyFAQ = () => {
  return (
    <FAQ
      faqTitle={'Frequently asked questions'}
      discordGA={() => {
        reportEvent({ name: GA_MAP.faqDiscord, data: { name: 'Click Discord Button' } });
      }}
      closeGA={() => {
        reportEvent({ name: GA_MAP.faqCloseButton, data: { name: 'Click Close Button' } });
      }}
      buttonGA={() => {
        reportEvent({ name: GA_MAP.faqButtonClick, data: { name: 'Click Help Button' } });
      }}
    >
      <FAQContent />
    </FAQ>
  );
};
