import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Tabs, TabList, Tab } from '@node-real/uikit';
import { mobileMedia } from '../hooks';
export const HeaderTab = (props) => {
    const { tabs, activeKey, onTabChange } = props;
    return (_jsx(CustomTabs, { activeKey: activeKey, onChange: onTabChange, children: _jsx(CustomTabList, { children: tabs.map((tab, index) => {
                if (tab?.key) {
                    return (_jsx(StyledTab, { tabKey: tab.key, children: tab.value }, index));
                }
                else {
                    return null;
                }
            }) }) }));
};
const CustomTabs = styled(Tabs) `
  width: 100%;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  max-width: 231px;
  margin-top: -8px;
  height: 64px;
  ${mobileMedia} {
    height: 40px;
    position: static;
    margin: 0px auto;
    max-width: 100%;
    transform: none;
  }
`;
const CustomTabList = styled(TabList) `
  gap: 32px;
  justify-content: center;
  border-bottom: none;
  height: 64px;
  ${mobileMedia} {
    gap: 0;
    height: 40px;
    width: 100%;
    width: 230px;
    justify-content: center;
    margin: 0 auto;
    & > div:not(:first-of-type) {
      margin-left: 30px;
    }
  }
`;
const StyledTab = styled(Tab) `
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: -4px;
  color: ${(props) => props.theme.colors.readable.normal};
  border-bottom: 4px solid transparent;
  &[data-selected] {
    font-weight: 600;
    border-bottom: 4px solid ${(props) => props.theme.colors.scene.primary.normal};
    color: ${(props) => props.theme.colors.readable.normal};
    &:hover {
      color: ${(props) => props.theme.colors.readable.normal};
    }
  }
  &:not([data-selected]):hover {
    font-weight: 500;
    border-bottom: 4px solid ${(props) => props.theme.colors.scene.primary.normal};
    color: ${(props) => props.theme.colors.readable.normal};
  }
  ${mobileMedia} {
    height: 40px;
  }
`;
