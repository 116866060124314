import styled from '@emotion/styled';
import { ColoredSuccessIcon, MenuCloseIcon, MenuOpenIcon } from '@node-real/icons';
import { Box, Button, Collapse, Flex, useDisclosure, useOutsideClick } from '@node-real/uikit';
import { useRef } from 'react';
import { mobileMedia } from '@op-bridge/bridge-core';

import { MAINNET_DOMAIN, NET_ENV, NetEnvList, TESTNET_DOMAIN } from '../../env';
import { GA_MAP, reportEvent } from '../../utils/ga';

export const SwitchEnv = () => {
  const { isOpen, onToggle } = useDisclosure();
  const ref = useRef(null);

  useOutsideClick({
    ref,
    handler: () => {
      if (isOpen) {
        onToggle();
      }
    },
  });

  return (
    <Flex justifyContent="flex-end">
      <Flex flexDirection="column" position="relative">
        <StyledButton
          ref={ref}
          onClick={() => {
            onToggle();
            reportEvent({ name: GA_MAP.headerConnect, data: { name: 'network' } });
          }}
          padding={['7.5px 4px 7.5px 8px', '7.5px 12px 7.5px 16px']}
        >
          <Box as="span" mr={['0', '2px']}>
            {NET_ENV}
          </Box>
          {isOpen ? (
            <MenuOpenIcon w="20px" h="20px" color="readable.secondary" />
          ) : (
            <MenuCloseIcon w="20px" h="20px" color="readable.secondary" />
          )}
        </StyledButton>
        <Collapse
          in={isOpen}
          padding="0"
          position="absolute"
          top="50px"
          left="0"
          w="140px"
          transform="translateX(-10px)"
        >
          <Box bg="readable.white" color="readable.border" borderRadius="4px">
            {NetEnvList.map((env) => {
              return (
                <Box padding="12px" key={env}>
                  <Link
                    href={env === 'Mainnet' ? MAINNET_DOMAIN : TESTNET_DOMAIN}
                    onClick={() => {
                      const reportName =
                        env === 'Mainnet' ? GA_MAP?.switchMainNet : GA_MAP?.switchTestNet;
                      reportEvent({ name: reportName, data: { name: 'network_dd' } });
                    }}
                  >
                    <Box as="span" fontSize="14px" fontWeight="500">
                      {env}
                    </Box>
                    {NET_ENV === env && (
                      <ColoredSuccessIcon w="20px" h="20px" color="scene.primary.normal" />
                    )}
                  </Link>
                </Box>
              );
            })}
          </Box>
        </Collapse>
      </Flex>
    </Flex>
  );
};

const StyledButton = styled(Button)`
  max-width: 158px;
  height: 44px;
  font-weight: 500;
  color: ${(props: any) => props.theme.colors.readable.normal};
  background: ${(props: any) => props.theme.colors.bg?.middle};
  border: 1px solid ${(props: any) => props.theme.colors.readable.border};
  border-radius: 200px;

  &:hover {
    cursor: pointer;
    background-color: ${(props: any) => props.theme.colors.readable?.border};
  }
  ${mobileMedia} {
    font-size: 14px;
    height: 24px;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
