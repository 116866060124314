import { CopyIcon, ColoredSuccessIcon } from '@node-real/icons';
import { useClipboard, Box, Tooltip, BoxProps, Flex } from '@node-real/uikit';
import { useCallback, useState } from 'react';

import { reportEvent, GA_MAP } from '../utils/ga';

export interface CopyProps extends BoxProps {
  value?: string;
  children?: React.ReactNode;
}

export const Copy = ({ value = '', children, ...restProps }: CopyProps) => {
  const { hasCopied, onCopy } = useClipboard(value ?? '');
  const [isHover, setIsHover] = useState(false);

  const handleCopy = useCallback(() => {
    if (value) {
      reportEvent({
        name: GA_MAP.profileCopy,
        data: { name: 'Click Copy Address' },
      });
      onCopy();
    }
  }, [onCopy, value]);

  return (
    <Tooltip
      bg="bg.normal"
      color="bg.middle"
      padding={8}
      borderRadius={4}
      border={'1px solid readable.top.secondary'}
      placement="top"
      fontSize={14}
      fontWeight={400}
      content={
        !hasCopied ? (
          'Copy'
        ) : (
          <Flex gap={4} gridGap={4} alignItems={'center'} justifyContent={'center'}>
            <ColoredSuccessIcon /> Copied
          </Flex>
        )
      }
      isOpen={isHover || hasCopied}
    >
      <Box
        onClick={handleCopy}
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        cursor="pointer"
        display="inline-block"
        {...restProps}
      >
        {children || <CopyIcon color={isHover ? 'scene.primary.active' : 'bg?.card'} />}
      </Box>
    </Tooltip>
  );
};
