import { NetEnvList } from '../env';

// https://developers.google.com/tag-platform/gtagjs/reference
export type TGtagCommand = 'config' | 'set' | 'get' | 'event' | 'consent';

export interface IReportEvent {
  command?: TGtagCommand;
  name: string;
  data: { [key: string]: string };
  setData?: { [key: string]: string };
}

export const getGtag = () => (window as any).gtag;
export const G_TAG: Record<typeof NetEnvList[number], string> = {
  Mainnet: 'G-Q3QBE414YZ',
  Testnet: 'G-VH2ZQLVW6E',
};

// https://developers.google.com/tag-platform/gtagjs/configure
export function reportEvent({ command = 'event', name, data }: IReportEvent) {
  if (typeof window === 'undefined' || !(window as any).gtag) return;

  (window as any).gtag(command, name, data);
}

export const GA_MAP = {
  home: 'op_brg.main.all.pv',
  terms: 'op_brg.main.terms.button.click',
  headerLogo: 'op_brg.main.header.logo.click',
  headerConnect: 'op_brg.main.header.connect.click',
  headerDisconnectClick: 'op_brg.main.header.profile_disc.click',
  headerDepositHistory: 'op_brg.main.header.profile_d_his.click',
  headerWithdrawHistory: 'op_brg.main.header.profile_w_his.click',
  profileCopy: 'op_brg.main.header.profile_copy.click',
  depositConnectButton: 'op_brg.deposit.connect.button.click',
  withdrawConnectButton: 'op_brg.withdraw.connect.button.click',
  depositWalletClick: 'op_brg.deposit.connect.pp_mm.click',
  withdrawWalletClick: 'op_brg.withdraw.connect.pp_mm.click',
  depositWalletClose: 'op_brg.deposit.connect.pp_close.click',
  withdrawWalletClose: 'op_brg.withdraw.connect.pp_close.click',
  faucet: 'op_brg.deposit.faucet.button.click',
  deposit: 'op_brg.deposit.deposit.button.click',
  depositTokenClick: 'op_brg.deposit.token.click.click',
  tryAgain: 'op_brg.deposit.send_again.click',
  withdraw: 'op_brg.withdraw.withdraw.button.click',
  withdrawTokenClick: 'op_brg.withdraw.token.click.click',
  prove: 'op_brg.history.withdraw.prove.click',
  finalize: 'op_brg.history.withdraw.final.click',
  approve: 'op_brg.deposit.approve_set.button.click',
  resetApprove: 'op_brg.deposit.approve_reset.button.click',
  viewl1Click: 'op_brg.deposit.submitted.explorer.click',
  thirdPartyClick: 'op_brg.3rd_party.poly.card.click',
  withdrawThirdPartyClick: 'op_brg.withdraw.tab.3rd.click',
  withdrawOfficialClick: 'op_brg.withdraw.tab.official.click',
  depositHistory: 'op_brg.deposit.submitted.history.click',
  depositTokenSelectionButton: 'op_brg.deposit.token.select.click',
  withdrawTokenSelectionButton: 'op_brg.withdraw.token.select.click',
  withdrawPopupClose: 'op_brg.wtd.popup.close.click',
  withdrawInitClick: 'op_brg.wtd.popup.initiate.click',
  initTxLinkClick: 'op_brg.wtd.popup.transaction1.click',
  withdrawProveClick: 'op_brg.wtd.popup.prove.click',
  proveTxLinkClick: 'op_brg.wtd.popup.transaction2.click',
  withdrawFinalizeClick: 'op_brg.wtd.popup.finalize.click',
  finalizeTxLinkClick: 'op_brg.wtd.popup.transaction3.click',
  withdrawPopupSwitch: 'op_brg.wtd.popup.switch_network.click',
  withdrawPopupCheckBox: 'op_brg.wtd.popup.checkbox.click',
  withdrawPopupHistoryLink: 'op_brg.wtd.popup.history.click',
  depositSwitch: 'op_brg.deposit.switch.button.click',
  withdrawSwitch: 'op_brg.withdraw.switch.button.click',
  depositAgain: 'op_brg.deposit.submitted.again.click',
  withdrawAgain: 'op_brg.withdraw.submitted.again.click',
  depositPPClose: 'op_brg.deposit.submitted.close.click',
  step1ViwExplorer: 'op_brg.withdraw.step1.explorer.click',
  step2ViwExplorer: 'op_brg.withdraw.step2.explorer.click',
  step3ViwExplorer: 'op_brg.withdraw.step3.explorer.click',
  step1ViewHistory: 'op_brg.withdraw.step1.prove.click',
  step2ViewHistory: 'op_brg.withdraw.step2.final.click',
  step3ViewHistory: 'op_brg.withdraw.step3.history.click',
  step1Close: 'op_brg.withdraw.step1.close.click',
  step2Close: 'op_brg.withdraw.step2.close.click',
  step3Close: 'op_brg.withdraw.step3.close.click',
  historyDepositTab: 'op_brg.history.deposit.tab.click',
  historyWithdrawTab: 'op_brg.history.withdraw.tab.click',
  withdrawDetailsButton: 'op_brg.history.withdraw.details.click',
  switchEnvDropdown: 'op_brg.header.network.dropdown.click',
  switchTestNet: 'op_brg.header.network_dd.testnet.click',
  switchMainNet: 'op_brg.header.network_dd.mainnet.click',
  footerDiscord: 'op_brg.main.footer.discord.click',
  footerTwitter: 'op_brg.main.footer.twitter.click',
  footerYoutube: 'op_brg.main.footer.youtube.click',
  footerTelegram: 'op_brg.main.footer.telegram.click',
  footerGithub: 'op_brg.main.footer.github.click',
  faqButtonClick: 'op_brg.main.help.button.click',
  faqQuestion: 'op_brg.main.help.question.click',
  faqDiscord: 'op_brg.main.help.discord.click',
  faqCloseButton: 'op_brg.main.help.close.click',
  faqRhino: 'op_brg.3rd_party.rhino.card.click ',
  ongoingShow: 'op_brg.main.ongoing.list.show',
  ongoingProveClick: 'op_brg.main.ongoing.prove.click',
  ongoingFinalizeClick: 'op_brg.main.ongoing.finalize.click',
  ongoingDetailsClick: 'op_brg.main.ongoing.details.click',
  ongoingHideClick: 'op_brg.main.ongoing.hide.click',
  autoWithdrawSwitch: 'op_brg.wtd.auto_popup.switch.click',
  autoWithdrawSwitchInfo: 'op_brg.wtd.auto_popup.switch_i.click',
  autoWithdrawGasInfo: 'op_brg.wtd.auto_popup.gas_i.click',
  autoWithdrawCheckboxClick: 'op_brg.wtd.auto_popup.checkbox.click',
  autoWithdrawButton: 'op_brg.wtd.auto_popup.withdraw.click',
  autoWithdrawClose: 'op_brg.wtd.auto_popup.close.click',
  autoWithdrawTx1: 'op_brg.wtd.auto_popup.transaction1.click',
  autoWithdrawTx2: 'op_brg.wtd.auto_popup.transaction2.click',
  autoWithdrawTx3: 'op_brg.wtd.auto_popup.transaction3.click',
  autoWithdrawHistory: 'op_brg.wtd.auto_popup.history.click',
  autoWithdrawApprove: 'op_brg.wtd.auto_popup.approve.click',
  depositMaxButton: 'op_brg.deposit.max.button.click',
  withdrawMaxButton: 'op_brg.withdraw.max.button.click',
};
