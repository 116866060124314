import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Flex } from '@node-real/uikit';
import { Loading } from '../Loading';
export function WalletItem(props) {
    const { icon, name, isDisabled, isActive, onClick, ...restProps } = props;
    const onBeforeClick = (e) => {
        if (isDisabled) {
            return;
        }
        onClick?.(e);
    };
    return (_jsxs(Flex, { alignItems: "center", justifyContent: "center", h: 68, borderRadius: 8, border: 'none', color: 'bg.card', cursor: isDisabled ? 'not-allowed' : 'pointer', _hover: {
            background: 'scene.primary.semiOpacity',
        }, bg: "readable.top.secondary", position: "relative", fontSize: 18, lineHeight: "22px", fontWeight: 600, transitionDuration: "normal", transitionProperty: "colors", onClick: onBeforeClick, _notLast: {
            mb: 16,
        }, ...restProps, children: [_jsx(Center, { position: "absolute", left: 16, children: icon }), name, isActive && (_jsx(Loading, { style: { position: 'absolute', width: '24px', right: '24px' }, minHeight: 24 }))] }));
}
