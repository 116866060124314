import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IBase {
  tokenList:
    | {
        name: string;
        symbol: string;
        l1Address: string;
        l2Address: string;
        logoURI: string;
        extensions?: {
          officialBridgeStatus: any;
        };
      }[]
    | [];
}

const initialState: IBase = {
  tokenList: [],
};

export const baseSlice = createSlice({
  name: 'tokenList',
  initialState,
  reducers: {
    updateTokenList: (state, action: PayloadAction<IBase>) => {
      state.tokenList = action.payload.tokenList;
    },
  },
});
