import styled from '@emotion/styled';
import { CollapseQuestion, mobileMedia } from '@op-bridge/bridge-core';
import { Box, Link } from '@node-real/uikit';

import { NET_ENV, OPBNB_SCAN } from '../../env';
import { GA_MAP, reportEvent } from '../../utils/ga';

export const FAQContent = () => {
  return (
    <>
      <CollapseQuestion
        buttonText="What is opBNB Bridge?"
        questionGA={() => {
          reportEvent({
            name: GA_MAP.faqQuestion,
            data: { name: 'What is opBNB Bridge?' },
          });
        }}
      >
        <Content>
          The official opBNB Bridge enables you to transfer tokens (BNB and other BEP-20 tokens)
          between BNB Smart Chain (BSC) and opBNB Chain, a layer-2 solution that delivers lower fees
          and higher throughput.
        </Content>
      </CollapseQuestion>
      <CollapseQuestion
        buttonText="What wallets are supported on the official opBNB Bridge?"
        questionGA={() => {
          reportEvent({
            name: GA_MAP.faqQuestion,
            data: { name: 'Click What wallets are supported on the official opBNB Bridge?' },
          });
        }}
      >
        <Content>
          MetaMask and Trust Wallet are supported by default. As for other wallets, we suggest you
          check the list of wallets supported by "WalletConnect".
        </Content>
      </CollapseQuestion>
      <CollapseQuestion
        buttonText="What tokens are supported on the official opBNB Bridge?"
        questionGA={() => {
          reportEvent({
            name: GA_MAP.faqQuestion,
            data: { name: 'What tokens are supported on the official opBNB Bridge?' },
          });
        }}
      >
        <Content>
          We now support BNB, BTCB, ETH, FDUSD, USDT, etc. For more upcoming tokens, feel free to
          check the official token list page on GitHub (
          <Link
            href={'https://github.com/bnb-chain/opbnb-bridge-tokens/blob/main/opbnb.tokenlist.json'}
            target="_blank"
          >
            https://github.com/bnb-chain/opbnb-bridge-tokens/blob/main/opbnb.tokenlist.json
          </Link>
          ).
        </Content>
      </CollapseQuestion>

      <CollapseQuestion
        buttonText="How long does it take to transfer tokens between BNB Smart Chain (BSC) and opBNB?"
        questionGA={() => {
          reportEvent({
            name: GA_MAP.faqQuestion,
            data: {
              name: 'How long does it take to transfer tokens between BNB Smart Chain (BSC) and opBNB?',
            },
          });
        }}
      >
        <Content>
          Deposit tokens from BSC to opBNB is relatively easy and fast. Depending on your wallet's
          gas fee settings, a single deposit may only take a few seconds to complete.
          <br />
          <br />
          Withdraw tokens from opBNB to BSC may be more complicated. You must manually operate 3
          steps to complete (#1. Initiate Withdrawal; #2 Return after 2 hrs to Prove Withdrawal; #3.
          Return after 7 days to Finalize Withdrawal). The overall process takes at least 7 days for
          opBNB mainnet, and about 2 hours for opBNB testnet.
        </Content>
      </CollapseQuestion>
      <CollapseQuestion
        buttonText="If I don't want to wait for 7 days to withdraw my tokens, are there any fast bridges available?"
        questionGA={() => {
          reportEvent({
            name: GA_MAP.faqQuestion,
            data: {
              name: `If I don't want to wait for 7 days to withdraw my tokens, are there any fast bridges available?`,
            },
          });
        }}
      >
        <Content>
          Yes, we do have several 3rd-party bridges available for fast withdrawals, including{' '}
          <Link href={'https://zkbridge.com/opbnb'} target="_blank">
            zkBridge
          </Link>{' '}
          and{' '}
          <Link
            href={'https://app.rhino.fi/bridge?token=BNB&chainOut=BINANCE&chain=OPBNB'}
            target="_blank"
          >
            rhino.fi bridge
          </Link>
          .<br />
          <br />
          But, these 3rd-party bridges are independent service providers that the BNB Chain team
          links to for your convenience. BNB Chain has no responsibility for their operations.
        </Content>
      </CollapseQuestion>

      <CollapseQuestion
        buttonText="Why are the estimated gas fees for withdraw/deposit transactions different between opBNB bridge and my wallet?"
        questionGA={() => {
          reportEvent({
            name: GA_MAP.faqQuestion,
            data: {
              name: `Why are the estimated gas fees for withdraw/deposit transactions different between opBNB bridge and my wallet?`,
            },
          });
        }}
      >
        <Content>
          When you use the bridge to deposit or withdraw tokens from opBNB, the bridge will estimate
          the gas cost for your transaction. This is done by simulating the execution of the
          transaction on the blockchain without actually sending it or changing the state of the
          network. The simulation returns a number that indicates how many gas units the transaction
          would use if it were executed in the current network state.
          <br />
          <br />
          However, the wallet you use to interact with the bridge may use a different method to
          calculate the estimated transaction cost. It may use the gas limit, which is the maximum
          amount of gas that you are willing to spend on the transaction. This is usually higher
          than the estimate given by the bridge.
          <br />
          <br />
          Once the transaction is executed on the chain, you can see the actual cost of the
          transaction in the{' '}
          <Link href={OPBNB_SCAN[NET_ENV]} target="_blank">
            opBNB explorer
          </Link>
          , which is usually similar to the estimate given by the bridge.
        </Content>
      </CollapseQuestion>
    </>
  );
};

const Content = styled(Box)`
  margin-left: 22px;
  margin-top: 8px;
  color: ${(props: any) => props.theme.colors?.bg?.card};
  font-size: 12px;
  font-weight: 400;
  line-height: normal;

  a {
    color: ${(props: any) => props.theme.colors.scene?.warning?.normal};
  }

  ${mobileMedia} {
    color: ${(props: any) => props.theme.colors.readable?.secondary};
  }
`;
