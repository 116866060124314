import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { baseSlice } from './baseSlice';

const combinedReducer = combineReducers({
  base: baseSlice.reducer,
});

export const reducers: Reducer<ReturnType<typeof combinedReducer>, AnyAction> = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};
