import NextApp, { AppProps } from 'next/app';
import axios from 'axios';
import { WagmiConfig, createClient, Chain } from 'wagmi';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, useEffect } from 'react';
import {
  metaMask,
  trustWallet,
  walletConnect,
  coinbaseWallet,
  okxWallet,
  binanceWeb3Wallet,
  bitgetWallet,
  // mathWallet,
} from '@node-real/walletkit/wallets';
import { WalletKitOptions, WalletKitProvider, getDefaultConfig } from '@node-real/walletkit';
import { ThemeProvider, isMobile } from '@node-real/uikit';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { Provider } from 'react-redux';

import '@node-real/walletkit/styles.css';

import Layout from '@/components/layout';
import { theme } from '@/theme';
import * as env from '@/env';
import { ModalProvider } from '@/context/modal';
import { NET_ENV, BSC_SCAN, OPBNB_SCAN } from '@/env';
import { BSCLogo } from '@/components/svgIcon/BSCLogo';
import { wrapper } from '@/store';
import { baseSlice } from '../store/baseSlice';

declare global {
  interface Window {
    trustWallet?: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
    },
  },
  queryCache: new QueryCache({
    onError: () => {},
  }),
});

export const bscTestnet: Chain = {
  id: Number(env.L1_CHAIN_ID),
  network: `BNB Smart Chain ${NET_ENV}`,
  rpcUrls: {
    default: {
      http: [env.L1_RPC_URL],
    },
    public: {
      http: [env.L1_RPC_URL],
    },
  },
  name: `BNB Smart Chain ${NET_ENV}`,
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  blockExplorers: {
    etherscan: { name: 'BscScan', url: BSC_SCAN[NET_ENV] },
    default: { name: 'BscScan', url: BSC_SCAN[NET_ENV] },
  },
};

export const opChain: Chain = {
  id: Number(env.L2_CHAIN_ID),
  network: `opBNB ${NET_ENV}`,
  rpcUrls: {
    default: {
      http: [env.L2_RPC_URL],
    },
    public: {
      http: [env.L2_RPC_URL],
    },
  },
  name: `opBNB ${NET_ENV}`,
  nativeCurrency: {
    name: NET_ENV === 'Mainnet' ? 'BNB' : 'tcBNB',
    symbol: NET_ENV === 'Mainnet' ? 'BNB' : 'tcBNB',
    decimals: 18,
  },
  blockExplorers: {
    etherscan: { name: 'opBNBScan', url: OPBNB_SCAN[NET_ENV] },
    default: { name: 'opBNBScan', url: OPBNB_SCAN[NET_ENV] },
  },
};

const mobile = isMobile();

const connectors = mobile
  ? [
      trustWallet({
        connectorOptions: {
          shimDisconnect: true,
        },
      }),
      metaMask({
        connectorOptions: {
          shimDisconnect: true,
          UNSTABLE_shimOnConnectSelectAccount: true,
        },
      }),
      okxWallet(),
      coinbaseWallet(),
      walletConnect({
        connectorOptions: {
          qrModalOptions: {
            explorerRecommendedWalletIds: [
              '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
              '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
              // 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', // coinbase wallet
              '7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26',
              'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
              '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
            ],
          },
        },
      }),
    ]
  : [
      trustWallet({
        connectorOptions: {
          shimDisconnect: true,
        },
      }),
      metaMask({
        connectorOptions: {
          shimDisconnect: true,
          UNSTABLE_shimOnConnectSelectAccount: true,
        },
      }),
      okxWallet(),
      coinbaseWallet(),
      walletConnect({
        connectorOptions: {
          qrModalOptions: {
            explorerRecommendedWalletIds: [
              '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
              '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
              // 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', // coinbase wallet
              '7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26',
              'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
              '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
            ],
          },
        },
      }),
    ];

// Add bitget wallet for mainnet
if (NET_ENV === 'Mainnet') {
  connectors.splice(-1, 0, binanceWeb3Wallet());
  connectors.splice(-1, 0, bitgetWallet());
}

const client = createClient(
  getDefaultConfig({
    appName: 'Connect Wallet',
    chains: [bscTestnet, opChain],
    connectors,
    autoConnect: true,
    walletConnectProjectId: '02040edbce5ab6bdf8d622e3630a326c',
  }),
);

function getDefaultLayout(page: ReactNode) {
  return <Layout page={page} />;
}

const MyApp = ({ Component, pageProps, ...otherProps }: AppProps) => {
  const router = useRouter() as any;
  const { store } = wrapper.useWrappedStore(otherProps);
  const chainId =
    router && router?.asPath?.includes('withdraw')
      ? Number(env.L2_CHAIN_ID)
      : Number(env.L1_CHAIN_ID);

  const connectWalletOptions: WalletKitOptions = {
    initialChainId: chainId, // Once connected to the wallet, which chain you want to use
    walletDownloadUrl: mobile ? 'https://metamask.io/download' : 'https://trustwallet.com',
    chainsConfig: [
      {
        id: Number(env.L2_CHAIN_ID),
        name: `opBNB ${NET_ENV}`,
        logo: <BSCLogo />,
      },
    ],
  };

  const getLayout = (Component as any).getLayout || getDefaultLayout;

  useEffect(() => {
    if (/localhost|fe\.nodereal\.cc|(\d|\.){4}/.test(window.location.origin)) {
      try {
        const VConsole = require('vconsole');
        new VConsole();
      } catch (err) {}
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={client}>
        <ThemeProvider theme={theme}>
          <WalletKitProvider options={connectWalletOptions} debugMode>
            <Provider store={store}>
              <Head>
                <title>
                  {`opBNB Bridge ${
                    NET_ENV === 'Testnet' ? '(Testnet) ' : ''
                  }- Transfer Tokens between BSC & opBNB`}
                </title>
              </Head>
              <ModalProvider>{getLayout(<Component {...pageProps} />)}</ModalProvider>
            </Provider>
          </WalletKitProvider>
        </ThemeProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
};

MyApp.getInitialProps = wrapper.getInitialAppProps((store) => async (appContext) => {
  if (NET_ENV === 'Mainnet') {
    const tokenList = await axios.get(env.GITHUB_TOKEN_URL);
    try {
      store.dispatch(
        baseSlice.actions.updateTokenList({
          tokenList: tokenList.data.tokens,
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Failed to get token list.');
    }
  }
  const appProps = await NextApp.getInitialProps(appContext);
  return { ...appProps };
});

export default MyApp;
