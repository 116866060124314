import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Button, Flex, Menu, MenuList, MenuItem, MenuButton, Box } from '@node-real/uikit';
import { MenuCloseIcon } from '@node-real/icons';
import { useAccount } from 'wagmi';
import { TokenBalance } from './TokenBalance';
// @ts-ignore
export const TokenSelection = forwardRef((props, ref) => {
    const { isConnected } = useAccount();
    const { handleTokenSelection, value, type = 'deposit', menus, onMenuButtonClick, l1ChainId, l2ChainId, l1Rpc, l2Rpc, loadingComponent, assetList, } = props;
    return (_jsxs(Menu, { placement: "bottom-end", children: [_jsxs(MenuButton, { onClick: onMenuButtonClick, as: CustomMenuButton, children: [_jsx(Box, { mr: 8, children: menus.filter((item) => item.name === value)[0]?.icon }), _jsx("div", { children: value })] }), _jsx(MenuList, { borderRadius: 8, background: 'readable.normal', children: menus.map((item, index) => (_jsxs(MenuItem, { className: value === item.name ? 'selected' : '', minW: 115, p: 12, justifyContent: 'space-between', alignContent: 'center', background: 'readable.normal', _hover: { background: 'bg.top.normal' }, sx: {
                        '&.selected': {
                            backgroundColor: 'scene.primary.semiOpacity',
                        },
                    }, onClick: () => {
                        handleTokenSelection(item.name);
                    }, children: [_jsxs(Flex, { alignItems: 'center', children: [_jsx(Box, { mr: 4, children: item.icon }), _jsx(Box, { color: 'bg.card', children: item.name })] }), isConnected ? (_jsx(TokenBalance, { type: type, tokenName: item.name, l1ChainId: l1ChainId, l2ChainId: l2ChainId, l1Rpc: l1Rpc, l2Rpc: l2Rpc, loadingComponent: loadingComponent, assetList: assetList })) : null] }, index))) })] }));
});
const CustomMenuButton = forwardRef((props, ref) => {
    const { children, ...restProps } = props;
    return (_jsxs(Button, { ref: ref, w: 116, borderLeft: '1px solid readable.disabled', variant: "ghost", justifyContent: "space-between", background: 'bg.status', pl: [8, 12, 12], pr: [4, 12, 12], fontWeight: [700, 600, 600], fontSize: [16, 14, 14], lineHeight: '17px', border: 'none', mr: 1, borderTopRightRadius: 8, borderBottomRightRadius: 8, borderBottomLeftRadius: 0, borderTopLeftRadius: 0, _hover: {
            background: 'bg.status',
        }, _expanded: {
            '.close-icon': {
                transform: 'rotate(-180deg)',
            },
        }, ...restProps, children: [_jsx(Flex, { align: 'center', children: children }), _jsx(MenuCloseIcon, { className: "close-icon", transitionDuration: "normal" })] }));
});
