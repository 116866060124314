// import getConfig from './getConfig';

export const L1_RPC_URL = process.env.NEXT_PUBLIC_L1_RPC_URL || '';
export const L2_RPC_URL = process.env.NEXT_PUBLIC_L2_RPC_URL || '';
export const L1_CHAIN_ID = String(process.env.NEXT_PUBLIC_L1_CHAIN_ID || '97');
export const L2_CHAIN_ID = String(process.env.NEXT_PUBLIC_L2_CHAIN_ID || '91735');
export const AddressManager = process.env.NEXT_PUBLIC_ADDRESS_MANAGER || '';
export const L1CrossDomainMessenger = process.env.NEXT_PUBLIC_CROSS_DOMAIN_MESSENGER || '';
export const L1StandardBridge = process.env.NEXT_PUBLIC_STANDARD_BRIDGE || '';
export const StateCommitmentChain = process.env.NEXT_PUBLIC_STATE_COMMITMENT_CHAIN || '';
export const CanonicalTransactionChain = process.env.NEXT_PUBLIC_CANONICAL_TRANSACTION_CHAIN || '';
export const BondManager = process.env.NEXT_PUBLIC_BOND_MANAGER || '';
export const OptimismPortal = process.env.NEXT_PUBLIC_OPTIMISM_PORTAL || '';
export const L2OutputOracle = process.env.NEXT_PUBLIC_L2_OUTPUT_ORACLE || '';
// AUTO WITHDRAW
export const AUTO_WITHDRAW_ADDRESS = process.env.NEXT_PUBLIC_AUTO_WITHDRAW_ADDRESS || '';
export const LEGACY_ERC20_ETH = process.env.NEXT_PUBLIC_LEGACY_ERC20_ETH || '';

export const BACKEND_DOMAIN = process.env.NEXT_PUBLIC_BACKEND_DOMAIN || '';
export const TOKEN_SYMBOL = process.env.NEXT_PUBLIC_TOKEN_SYMBOL || 'BNB';
// USDT
export const USDT_ADDRESS_L1 = process.env.NEXT_PUBLIC_USDT_ADDRESS_L1 || '';
export const USDT_ADDRESS_L2 = process.env.NEXT_PUBLIC_USDT_ADDRESS_L2 || '';
// BTC
export const BTC_ADDRESS_L1 = process.env.NEXT_PUBLIC_BTC_ADDRESS_L1 || '';
export const BTC_ADDRESS_L2 = process.env.NEXT_PUBLIC_BTC_ADDRESS_L2 || '';
// ETH
export const ETH_ADDRESS_L1 = process.env.NEXT_PUBLIC_ETH_ADDRESS_L1 || '';
export const ETH_ADDRESS_L2 = process.env.NEXT_PUBLIC_ETH_ADDRESS_L2 || '';
// BUSD
export const BUSD_ADDRESS_L1 = process.env.NEXT_PUBLIC_BUSD_ADDRESS_L1 || '';
export const BUSD_ADDRESS_L2 = process.env.NEXT_PUBLIC_BUSD_ADDRESS_L2 || '';
// USDC
export const USDC_ADDRESS_L1 = process.env.NEXT_PUBLIC_USDC_ADDRESS_L1 || '';
export const USDC_ADDRESS_L2 = process.env.NEXT_PUBLIC_USDC_ADDRESS_L2 || '';
// DAI
export const DAI_ADDRESS_L1 = process.env.NEXT_PUBLIC_DAI_ADDRESS_L1 || '';
export const DAI_ADDRESS_L2 = process.env.NEXT_PUBLIC_DAI_ADDRESS_L2 || '';
// BTCB
export const BTCB_ADDRESS_L1 = process.env.NEXT_PUBLIC_BTCB_ADDRESS_L1 || '';
export const BTCB_ADDRESS_L2 = process.env.NEXT_PUBLIC_BTCB_ADDRESS_L2 || '';
// CAKE
export const CAKE_ADDRESS_L1 = process.env.NEXT_PUBLIC_CAKE_ADDRESS_L1 || '';
export const CAKE_ADDRESS_L2 = process.env.NEXT_PUBLIC_CAKE_ADDRESS_L2 || '';
// FDUSD
export const FDUSD_ADDRESS_L1 = process.env.NEXT_PUBLIC_FDUSD_ADDRESS_L1 || '';
export const FDUSD_ADDRESS_L2 = process.env.NEXT_PUBLIC_FDUSD_ADDRESS_L2 || '';

// DOMAIN
export const TESTNET_DOMAIN = process.env.NEXT_PUBLIC_TESTNET_DOMAIN;
export const MAINNET_DOMAIN = process.env.NEXT_PUBLIC_MAINNET_DOMAIN;

// NET ENV
export const NetEnvList = ['Mainnet', 'Testnet'] as const;
export const BSC_SCAN: Record<typeof NetEnvList[number], string> = {
  Mainnet: 'https://bscscan.com',
  Testnet: 'https://testnet.bscscan.com',
};
export const OPBNB_SCAN: Record<typeof NetEnvList[number], string> = {
  Mainnet: 'https://opbnbscan.com',
  Testnet: 'https://testnet.opbnbscan.com',
};
export const PROVE_GAS: Record<typeof NetEnvList[number], number> = {
  Mainnet: 300000,
  Testnet: 370000,
};

export const PROVE_WAITING_PERIOD: Record<typeof NetEnvList[number], number> = {
  Mainnet: 60 * 60 * 1, // 1hr
  Testnet: 60 * 15, // 15mins
};

export const FINALIZE_WAITING_PERIOD: Record<typeof NetEnvList[number], number> = {
  Mainnet: 60 * 60 * 24 * 7, // 7days
  Testnet: 60 * 30, // 30mins
};

// export const NET_ENV: typeof NetEnvList[number] = NEXT_PUBLIC_NET_ENV || 'Mainnet';
export const NET_ENV: typeof NetEnvList[number] =
  (process.env.NEXT_PUBLIC_NET_ENV as typeof NetEnvList[number]) || 'Mainnet';

export const DEPOSIT_COEFFICIENT: Record<typeof NetEnvList[number], number> = {
  Mainnet: 1.02,
  Testnet: 1.02,
};

export const WITHDRAW_COEFFICIENT: Record<typeof NetEnvList[number], number> = {
  Mainnet: 1.005,
  Testnet: 1.02,
};

export const GITHUB_TOKEN_URL =
  'https://raw.githubusercontent.com/bnb-chain/opbnb-bridge-tokens/main/opbnb.tokenlist.json';
// 'https://raw.githubusercontent.com/hannahhjh/test/main/tokenlist.json';
// NET_ENV === 'Testnet'
//  'https://raw.githubusercontent.com/hannahhjh/test/main/testnetTokens' // from QA Testnet
// : 'https://raw.githubusercontent.com/bnb-chain/opbnb-bridge-tokens/main/opbnb.tokenlist.json';

export const OracleGetPriceAddress = '0x420000000000000000000000000000000000000F';
