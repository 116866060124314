import * as ethers from 'ethers';
import ReactDOM from 'react-dom';
import BN from 'bn.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const ZERO = new BN(0);

export const getEtherFormatValue = async (balance: ethers.BigNumber) => {
  try {
    return await ethers.utils.formatEther(balance);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return null;
  }
};

export const batchUpdate = (fn: () => void) => {
  ReactDOM.unstable_batchedUpdates(fn);
};

export const trimLongStr = (
  str: string,
  maxLength: number = 14,
  headLen: number = 4,
  footLen: number = 8,
) => {
  if (!str) {
    return '';
  }
  if (str.length > maxLength) {
    const head = str.substring(0, headLen);
    const foot = str.substring(str.length - footLen, str.length);
    return `${head}...${foot}`;
  }
  return str;
};

export const formatTime = (date: number | string) => {
  if (!date) {
    return '';
  }

  return dayjs(date)?.utc().format('YYYY-M-DD hh:mm:ss A');
};

export const formatLocalTime = (date: number | string, format?: string) => {
  if (!date) {
    return '';
  }

  return dayjs(date)
    ?.utc()
    .local()
    .format(format || 'YYYY-M-DD hh:mm:ss A');
};

export const getUTCZone = (date: number | string) => {
  if (!date) {
    return '';
  }

  const zone = dayjs(date)?.utc().local().format('Z')?.split(':')[0];
  return zone === '+00' || zone === '-00' ? '+' + Number(zone) : zone[0] + Number(zone);
};

export const removeTrailingZero = (str: string) => {
  const newStr = str.replace(/0+$/, '');
  return newStr.endsWith('.') ? newStr.slice(0, -1) : newStr;
};

export const getTxFee = (gasPrice?: number, gasUsed?: number, decimalLength: number = 8) => {
  if (!gasPrice || !gasUsed) {
    return '0';
  }
  let valStr = '';
  const a = new BN(gasPrice);
  const b = new BN(gasUsed);
  const bn = a.mul(b);
  valStr = divide10Exp(bn, 18);
  return removeTrailingZero(Number(valStr).toFixed(decimalLength));
};

export const divide10Exp = (origin: BN, pow: number) => {
  if (origin.eq(ZERO)) {
    return '0';
  }
  const divisor = new BN(10).pow(new BN(pow));
  if (origin.lt(divisor)) {
    const str = origin.toString(10, pow);
    return '0.' + removeTrailingZero(str);
  } else {
    const mod = origin.mod(divisor);
    const intPartStr = origin.div(divisor).toString();
    if (mod.eq(ZERO)) {
      return intPartStr;
    } else {
      return intPartStr + '.' + removeTrailingZero(mod.toString(10, pow));
    }
  }
};

const seperateStr = (str: string) => {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNum = (num: number | BN | string) => {
  if (BN.isBN(num)) {
    const str = num.toString(10);
    return seperateStr(str);
  }
  return Number(num).toLocaleString('fullwide', {
    maximumFractionDigits: 8,
  });
};

export const addDataIntoCache = async (url: string, rawData: any, cacheName = 'historyCache') => {
  const nowTime = Date.now();
  const data = new Response(JSON.stringify({ ...rawData, time: nowTime }));
  if ('caches' in window) {
    try {
      const cacheStorage = await caches.open(cacheName);
      const cachedResponse = await cacheStorage.match(url);

      if (cachedResponse) {
        let currentCache = await cachedResponse.json();
        let newCache = { ...currentCache, ...rawData, time: nowTime };
        const data = new Response(JSON.stringify(newCache));
        cacheStorage.put(url, data);
      } else {
        cacheStorage.put(url, data);
      }
    } catch (e) {
      //eslint-disable-next-line no-console
      console.log(e);
    }
  }
};

export const getDataFromCache = async (
  url: string,
  opts?: {
    cacheName?: string;
    deleteCallback?: () => void;
    deleteCacheWhenTimeOut?: boolean;
  },
) => {
  const cacheName = opts && opts.cacheName ? opts.cacheName : 'historyCache';
  // Opening that particular cache
  const nowTime = Date.now();
  try {
    const cacheStorage = await caches.open(cacheName);
    if (!cacheStorage) {
      return null;
    }
    let data = null;
    const cachedResponse = await cacheStorage.match(url);
    if (cachedResponse) {
      data = await cachedResponse.json();
      if (data?.time) {
        if (nowTime - data?.time > 60 * 5 * 1000 && opts?.deleteCacheWhenTimeOut !== false) {
          // remove old cache
          cacheStorage?.delete(url);
          // eslint-disable-next-line no-console
          console.log('delete tx status storage after 5 mins');
          // execute callback after delete cache
          if (opts?.deleteCallback) opts?.deleteCallback();
          return null;
        } else {
          return data;
        }
      } else {
        cacheStorage?.delete(url);
        return null;
      }
    }
    return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return null;
  }
};

export const deleteDataFromCache = async (url: string, key: string, cacheName = 'historyCache') => {
  // Opening that particular cache
  try {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);
    if (cachedResponse) {
      let data = await cachedResponse.json();
      if (data[key]) {
        delete data[key];
        const newData = new Response(JSON.stringify(data));
        cacheStorage.put(url, newData);
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
