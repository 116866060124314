import { useState } from 'react';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { useWalletSwitchNetWork } from './useWalletSwitchNetwork';
import { handleWalletError } from '../error/handleWalletError';
export function useWallet(props) {
    const { onSuccess, onConnectError, onSwitchNetworkError, chainId } = props;
    const { disconnectAsync, disconnect } = useDisconnect();
    const { isConnecting, isConnected } = useAccount();
    const [connector, setConnector] = useState();
    const [address, setAddress] = useState();
    const { connect, connectors } = useConnect({
        onError: (...params) => {
            handleWalletError(...params);
            onConnectError?.(...params);
        },
        onSuccess: (data) => {
            setAddress(data.account);
            if (data.chain.id === chainId) {
                onSuccess?.(data.account);
            }
            else {
                switchNetwork?.(chainId);
            }
        },
    });
    const { switchNetwork, isLoading } = useWalletSwitchNetWork({
        onError: (...params) => {
            handleWalletError(...params);
            onSwitchNetworkError?.(...params);
        },
        onSuccess: () => {
            onSuccess?.(address);
        },
    });
    const onChangeConnector = (connector) => {
        disconnectAsync().then(() => {
            setAddress('');
        });
        setTimeout(() => {
            setConnector(connector);
            connect({
                connector,
            });
        }, 200);
    };
    return {
        isLoading: (isConnecting && !isConnected) || isLoading,
        connector,
        connectors,
        onChangeConnector,
        disconnect,
    };
}
