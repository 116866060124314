import { ReactNode } from 'react';
import styled from '@emotion/styled';
// import { useRouter } from 'next/router';
import { mobileMedia, useResponsive } from '@op-bridge/bridge-core';

// import { useModal } from '../../hooks';
import Header from './Header';
import Footer from './Footer';
import { StickyFAQ } from '../faq/StickyFAQ';
import { StickyFAQMobile } from '../faq/StickyFAQMobile';
export default function Layout({ page }: { page: ReactNode }) {
  const { isMobile } = useResponsive();
  return (
    <>
      <Container>
        <Header />
        <Main>{page}</Main>
        {isMobile ? <StickyFAQMobile /> : <StickyFAQ />}
        <Footer />
      </Container>
    </>
  );
}

const Main = styled.main`
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 64px;
  margin-bottom: 32px;
  ${mobileMedia} {
    display: block;
    width: 100%;
    margin: 68px auto 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;
