import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@node-real/uikit';
import { useEffect, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';
import { useRouter } from 'next/router';
import { HeaderTab } from './HeaderTab';
export const MobileTab = () => {
    const { isConnected } = useAccount();
    const [connected, setIsConneted] = useState(false);
    const router = useRouter();
    const path = router.pathname;
    const [activeKey, setActiveKey] = useState(path);
    const tabs = useMemo(() => [
        { key: 'deposit', value: 'Deposit' },
        { key: 'withdraw', value: 'Withdraw' },
        connected ? { key: 'history', value: 'History' } : {},
    ], [connected]);
    useEffect(() => {
        const routerPath = path.replace('/', '');
        if (routerPath && tabs?.filter((tab) => tab.key === routerPath)) {
            setActiveKey(routerPath);
        }
    }, [path, tabs]);
    // Check client side wallet connection
    useEffect(() => {
        setIsConneted(isConnected);
    }, [isConnected]);
    const onTabChange = async (key) => {
        const tabName = String(key);
        router.push(`/${tabName}`);
    };
    return (_jsx(Box, { h: 40, children: _jsx(HeaderTab, { tabs: tabs, activeKey: activeKey, onTabChange: onTabChange }) }));
};
