import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
export const useGitHubTokenList = (githubUrl, nftType, enabled = true) => {
    let { data, isLoading } = useQuery([nftType], async () => {
        return (await axios.get(`${githubUrl}`)).data;
    }, {
        enabled: enabled,
        staleTime: 1000 * 30,
        cacheTime: 1000 * 30,
        // refetchOnMount: false,
    });
    return {
        data,
        isLoading,
    };
};
